import React from 'react'
import { TrackData } from '@interco/track'

import OrangeIcon from 'src/components/Icons'
import {
  Section,
  Title,
  Card,
  CardTitleWrapper,
  CardTitle,
  CardDescription,
  NumberDesc,
  Number,
  Divider,
  Link,
} from './style'

type SendDatalayerEventProps = {
  sendDatalayerEvent: Function;
}


export const CentralRelacionamento = ({sendDatalayerEvent}: SendDatalayerEventProps) => {
  
  const hanldeDataLayer = () => {
    TrackData.log({conteudo: 'Acessar canal da ouvidoria', url: window.location.href, evento: 'click' })
    sendDatalayerEvent({
      section: 'dobra_3',
      element_action: 'click button',
      element_name: 'Acessar canal da ouvidoria',
      section_name: 'Ainda precisa de ajuda?',
    })
  }

  return (
    <Section className='container pt-4'>
      <div className='row'>
        <div className='col-12'>
          <Title>Ainda precisa de ajuda?</Title>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-4'>
          <Card>
            <CardTitleWrapper>
              <div>
                <OrangeIcon size='MD' color='#161616' icon='phone' />
              </div>
              <CardTitle>Central de Relacionamento</CardTitle>
            </CardTitleWrapper>
            <CardDescription>
              Se quiser falar com a gente por telefone, nosso setor de
              atendimento está disponível a qualquer hora, é só ligar em um dos
              números:
            </CardDescription>
            <NumberDesc>Capitais e regiões metropolitanas</NumberDesc>
            <Number>3003 4070</Number>
            <NumberDesc>Demais localidades</NumberDesc>
            <Number>0800 940 0007</Number>
            <NumberDesc>Deficiente de fala e audição</NumberDesc>
            <Number>0800 979 7099</Number>
          </Card>
        </div>
        <div className='col-12 col-md-6 col-lg-4'>
          <Card>
            <CardTitleWrapper>
              <div>
                <OrangeIcon size='MD' color='#161616' icon='phone' />
              </div>
              <CardTitle>SAC</CardTitle>
            </CardTitleWrapper>
            <CardDescription>
              Para registrar sugestões, reclamações ou para fazer algum
              cancelamento, o SAC é o canal de atendimento ideal. Disponível
              todos os dias, 24h por dia.
            </CardDescription>
            <NumberDesc>Ligar para SAC</NumberDesc>
            <Number>0800 940 9999</Number>
          </Card>
        </div>
        <div className='col-12 col-md-6 col-lg-4'>
          <Card>
            <CardTitleWrapper>
              <div>
                <OrangeIcon size='MD' color='#161616' icon='phone' />
              </div>
              <CardTitle>Fale com a Ouvidoria</CardTitle>
            </CardTitleWrapper>
            <CardDescription>
              Para você que já recebeu nosso atendimento em outros canais, mas
              ainda não teve a solução que precisava.
              <br />
              <br />
              Se você já tem um protocolo registrado, fale com a gente no
              número: <strong>0800 940 7772</strong>, ou pelo{' '}
              <strong>canal da Ouvidoria.</strong>
            </CardDescription>
            <NumberDesc>Ligar para Ouvidoria</NumberDesc>
            <Number>0800 940 7772</Number>
            <Divider />
            <Link
              onClick={hanldeDataLayer}
              href={"https://www.bancointer.com.br/ouvidoria/?utm_source=HomeBabi&utm_medium=app"}
              target="_blank"
              rel="noopener noreferrer"
              >
                Acessar canal da ouvidoria
            </Link>
          </Card>
        </div>
      </div>
    </Section>
  )
}
